/* COLORS */
/* sass color calculations : http://razorjam.github.io/sasscolourfunctioncalculator/ */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background-color: #60696a;
  background: #60696a;
  font: 12px/1.5 Verdana, Arial, sans-serif;
  text-align: center;
  color: #333333;
}

#wrapper {
  background-color: #eff2ff;
  width: 1020px;
  margin: 0 auto;
  text-align: left;
}

.clear {
  clear: both;
  height: 1px;
}

.staging {
  background-color: #cc0000;
  color: #fff;
  font-size: 24px;
  padding: 5px;
  text-align: center;
}

#header {
  height: 50px;
  background: #eff2ff url(../images/admin_header_new.png) no-repeat;
}

#header h1 a {
  display: block;
  width: 480px;
  height: 50px;
  text-indent: -9999px;
  font-size: 18px;
  color: #003366;
}

#nav {
  padding: 2px;
  background: #333;
}

#nav h1 {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
}

#footer {
  padding: 10px 0;
  border-top: 3px solid #abd5df;
  background-color: #eff2ff;
  clear: both;
  text-align: center;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #999999;
}

#footer a {
  color: #666666;
}

#content {
  background-color: #fff;
  padding: 10px 20px;
}

#content a {
  text-decoration: none;
  color: #93b6bf;
}

#content a:hover {
  text-decoration: underline;
  color: #78959d;
}

#content h1 {
  margin: 2em 0;
  text-align: center;
  font-size: 2em;
  color: #c00;
  font-weight: normal;
  letter-spacing: 1px;
}

#content p {
  margin-bottom: 1em;
  font-size: 1em;
}


